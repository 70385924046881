<template>
    <div class="page flex-col">
        <div class="section_1 flex-col">
            <div class="group_1 flex-col justify-end">
                <div class="block_1 flex-row">
                    <img class="image_1" referrerpolicy="no-referrer"
                        src="./assets/img/psv562on7218y8o8te5rkede4cdiwbl8j81a684657-bb01-411e-929e-c43c82fc8a13.png" />
                    <span class="text_1">首页</span>
                    <!-- <span class="text_2">产品</span> -->
                    <span class="text_3">商务联系：18948165467</span>
                </div>
                <div class="block_2 flex-row">
                    <div class="box_1 flex-col"></div>
                </div>
            </div>
            <span class="text_4">全方位解决出海获客，私域运营</span>
            <span class="paragraph_1">
                集成全球主流社媒、IM平台，为出海卖家提供多平台的私域获客方案，
                <br />
                助力实现出海业务增长
            </span>
            <div class="text-wrapper_1 flex-col"><span class="text_5">立即咨询</span></div>
        </div>
        <div class="section_2 flex-col">
            <span class="text_6">Gosea营销系统支持的平台</span>
            <div class="block_3 flex-row justify-between">
                <div class="group_2 flex-row">
                    <div class="image-text_1 flex-row justify-between">
                        <div class="box_2 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/ws.png" />
                        </div>
                        <span class="text-group_1">WhatsApp</span>
                    </div>
                </div>
                <div class="group_3 flex-row">
                    <div class="image-text_2 flex-row justify-between">
                        <div class="section_3 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/fb.png" /></div>
                        <span class="text-group_2">Facebook</span>
                    </div>
                </div>
                <div class="group_4 flex-row">
                    <div class="section_4 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                        src="./assets/img/zalo.png" /></div>
                    <span class="text_7">Zalo</span>
                </div>
                <div class="group_5 flex-row">
                    <div class="image-text_3 flex-row justify-between">
                        <div class="block_4 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/tk.png" /></div>
                        <span class="text-group_3">Tiktok</span>
                    </div>
                </div>
                <div class="group_6 flex-row">
                    <div class="image-text_4 flex-row justify-between">
                        <div class="group_7 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/ins.png" /></div>
                        <span class="text-group_4">Instagram</span>
                    </div>
                </div>
            </div>
            <div class="block_5 flex-row justify-between">
                <div class="box_3 flex-row">
                    <div class="image-text_5 flex-row justify-between">
                        <div class="group_8 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/twi.png" /></div>
                        <span class="text-group_5">Twitter</span>
                    </div>
                </div>
                <div class="box_4 flex-row">
                    <div class="image-text_6 flex-row justify-between">
                        <div class="block_6 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/tg.png" /></div>
                        <span class="text-group_6">Telegram</span>
                    </div>
                </div>
                <div class="box_5 flex-row">
                    <div class="group_9 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                        src="./assets/img/line.png" /></div>
                    <span class="text_8">Line</span>
                </div>
                <div class="box_6 flex-row">
                    <div class="image-text_7 flex-row justify-between">
                        <div class="group_10 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/sk.png" /></div>
                        <span class="text-group_7">Skype</span>
                    </div>
                </div>
                <div class="box_7 flex-row">
                    <div class="image-text_8 flex-row justify-between">
                        <div class="block_7 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                            src="./assets/img/dd.png" /></div>
                        <span class="text-group_8">敬请期待</span>
                    </div>
                </div>
            </div>
            <div class="block_8 flex-col">
                <span class="text_9">我们了解你在出海中的痛点</span>
                <div class="list_1 flex-row">
                    <div class="list-items_1 flex-col" v-for="(item, index) in loopData0" :key="index">
                        <img class="image_2" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
                        <span class="text_10" v-html="item.lanhutext0"></span>
                        <span class="text_11" v-html="item.lanhutext1"></span>
                    </div>
                </div>
            </div>
            <span class="text_12">出海营销获客系统-Gosea</span>
            <div class="block_9 flex-row">
                <div class="text-wrapper_2 flex-col">
                    <span class="text_13">Gosea系统</span>
                    <span class="text_14">
                        全球多渠道多维度获客，解决获客难题。一站式集成全球二十多个IM、社媒平台，为出海提供全面的准备。
                    </span>
                    <span class="text_15">
                        支持WhatsApp、Facebook、Zalo、Twitter等多平台的批量自动化注册、养号、群发消息等功能，帮企业找到精准的客户，提供专业的海外运营方案指导，帮助企业更快的拓展海外业务。
                    </span>
                </div>
                <div class="box_8 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                    src="./assets/img/sm_b.png" /></div>
            </div>
            <div class="text-wrapper_3 flex-col"><span class="text_16">立即咨询</span></div>
            <div class="block_10 flex-col">
                <div class="text-wrapper_4 flex-row"><span class="text_17">Gosea营销系统的核心功能</span></div>
                <div class="box_9 flex-row justify-between">
                    <div class="group_11 flex-col">
                        <div class="group_12 flex-row justify-between">
                            <span class="text_18">自动化注册</span>
                            <div class="text-wrapper_5 flex-col"><span class="text_19">HOT</span></div>
                        </div>
                        <span class="paragraph_2">
                            自动注册账号，模拟真实用户
                            <br />
                            自动完善账号信息
                        </span>
                        <div class="text-wrapper_6 flex-col"><span class="text_20">立即咨询</span></div>
                    </div>
                    <div class="group_13 flex-col justify-between">
                        <div class="block_11 flex-col">
                            <div class="block_12 flex-row justify-between">
                                <span class="text_21">消息群发</span>
                                <div class="text-wrapper_7 flex-col"><span class="text_22">HOT</span></div>
                            </div>
                            <span class="text_23">精准点对点消息发送、群组发送、群组成员发送</span>
                        </div>
                        <div class="block_13 flex-col">
                            <div class="block_14 flex-row justify-between">
                                <span class="text_24">邀请进群</span>
                                <div class="text-wrapper_8 flex-col"><span class="text_25">HOT</span></div>
                            </div>
                            <span class="paragraph_3">
                                无感邀请进鲜，克隆竞对群组内高净值用户，
                                <br />
                                提高项目转化
                            </span>
                        </div>
                    </div>
                    <div class="group_14 flex-col justify-between">
                        <div class="block_15 flex-col">
                            <div class="section_5 flex-row justify-between">
                                <span class="text_26">个性化剧本</span>
                                <div class="text-wrapper_9 flex-col"><span class="text_27">HOT</span></div>
                            </div>
                            <span class="paragraph_4">
                                自定义角色互动，活跃氛围，提高帐号权重及
                                <br />
                                生命周期
                            </span>
                        </div>
                        <div class="block_16 flex-col">
                            <div class="group_15 flex-row justify-between">
                                <span class="text_28">信息采集</span>
                                <div class="text-wrapper_10 flex-col"><span class="text_29">HOT</span></div>
                            </div>
                            <span class="paragraph_5">
                                多场景多渠道多纬度，客户信息精淮采集解决
                                <br />
                                获客难题
                            </span>
                        </div>
                    </div>
                </div>
                <div class="box_10 flex-row justify-between">
                    <div class="section_6 flex-col">
                        <div class="box_11 flex-row justify-between">
                            <span class="text_30">关键词监听</span>
                            <div class="text-wrapper_11 flex-col"><span class="text_31">HOT</span></div>
                        </div>
                        <span class="paragraph_6">
                            监听竞对群组，匹配监听关键词，主动私信营销
                            <br />
                            并采集
                        </span>
                    </div>
                    <div class="section_7 flex-col">
                        <div class="section_8 flex-row justify-between">
                            <span class="text_32">客户过滤</span>
                            <div class="text-wrapper_12 flex-col"><span class="text_33">HOT</span></div>
                        </div>
                        <span class="paragraph_7">
                            精准过滤高净值有效客户资源，筛选客户应用
                            <br />
                            场景
                        </span>
                    </div>
                    <div class="section_9 flex-col">
                        <div class="block_17 flex-row justify-between">
                            <span class="text_34">全网营销</span>
                            <div class="text-wrapper_13 flex-col"><span class="text_35">HOT</span></div>
                        </div>
                        <span class="paragraph_8">
                            自动加好友、发帖、评论等，实现精准意向客户
                            <br />
                            私信
                        </span>
                    </div>
                </div>
            </div>
            <span class="text_36">Gosea为客户提供稳定、可靠、安全的产品服务</span>
            <div class="block_18 flex-row justify-between">
                <div class="group_16 flex-col">
                    <div class="group_17 flex-row justify-between">
                        <div class="group_18 flex-col"></div>
                        <span class="paragraph_9">
                            出海营销获客系统使用须知，分配专属客
                            <br />
                            户成功经理
                        </span>
                    </div>
                    <div class="group_19 flex-row justify-between">
                        <div class="section_10 flex-col"></div>
                        <span class="paragraph_10">
                            建立售后服务群:3对1的服务，同步服务
                            <br />
                            清单
                        </span>
                    </div>
                    <div class="group_20 flex-row justify-between">
                        <div class="section_11 flex-col"></div>
                        <span class="text_37">开通出海营销获客平台账号&amp;功能权限</span>
                    </div>
                    <div class="group_21 flex-row justify-between">
                        <div class="group_22 flex-col"></div>
                        <span class="paragraph_11">
                            基础功能设置:接码平台、IP平台、云机
                            <br />
                            设置等
                        </span>
                    </div>
                </div>
                <div class="group_23 flex-col">
                    <div class="section_12 flex-row justify-between">
                        <div class="box_12 flex-col"></div>
                        <span class="paragraph_12">
                            出海营销获客系统详细的功能讲解、实操
                            <br />
                            解答
                        </span>
                    </div>
                    <div class="section_13 flex-row justify-between">
                        <div class="box_13 flex-col"></div>
                        <span class="text_38">对应平台功能使用注意事项、功能解答</span>
                    </div>
                    <div class="section_14 flex-row justify-between">
                        <div class="block_19 flex-col"></div>
                        <span class="paragraph_13">
                            产品操作使用手册、产品操作视频讲解、
                            <br />
                            系统使用FAQ
                        </span>
                    </div>
                </div>
                <div class="group_24 flex-col">
                    <div class="block_20 flex-row justify-between">
                        <div class="group_25 flex-col"></div>
                        <span class="text_39">全年服务群咨询服务</span>
                    </div>
                    <div class="block_21 flex-row justify-between">
                        <div class="box_14 flex-col"></div>
                        <span class="text_40">企业微信使用培训指导</span>
                    </div>
                    <div class="block_22 flex-row justify-between">
                        <div class="box_15 flex-col"></div>
                        <span class="text_41">出海营销获客后台使用指导</span>
                    </div>
                    <div class="block_23 flex-row justify-between">
                        <div class="section_15 flex-col"></div>
                        <span class="paragraph_14">
                            7*12小时服务指导:日常解答客户使用遇到
                            <br />
                            的问题
                        </span>
                    </div>
                    <div class="block_24 flex-row justify-between">
                        <div class="box_16 flex-col"></div>
                        <span class="paragraph_15">
                            功能更新上线同步:以周、月为单位进行能
                            <br />
                            上新和迭代
                        </span>
                    </div>
                </div>
            </div>
            <div class="block_25 flex-col"><img class="image_apk" referrerpolicy="no-referrer"
                src="./assets/img/sm_1.png" /></div>
            <div class="text-wrapper_14 flex-col"><span class="text_42">入门指导</span></div>
            <div class="text-wrapper_15 flex-col"><span class="text_43">日常服务指导</span></div>
            <div class="text-wrapper_16 flex-col"><span class="text_44">功能培训</span></div>
        </div>
        <div class="section_16 flex-row">
            <img class="image_3" referrerpolicy="no-referrer"
                src="./assets/img/psywel78a4j7591fek1nv2kc8kh8365iiub2cb6ab9-0268-4bdf-a2d8-bcabf0b8ec0f.png" />
            <span class="paragraph_16">
                产品
                <br />
                Gosea营销获客系统
                <br />
            </span>
            <span class="paragraph_17">
                联系我们
                <br />
                客服：18948165467
                <br />
                邮箱：public&#64;armclouding.com
            </span>
            <div class="block_26 flex-col justify-between">
                <span class="text_45">立即扫码咨询</span>
                <div class="box_17 flex-col">
                    <img class="image_com" referrerpolicy="no-referrer"
                        src="./assets/img/com.jpg" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loopData0: [
                    {
                        lanhuimage0:
                            'https://lanhu.oss-cn-beijing.aliyuncs.com/psa5btm6k7rro0dt169ah7igmylnwsd176un4aeed3f6-613c-4222-a5e1-0495bcec950a',
                        lanhutext0: '业务起步难',
                        lanhutext1: '缺少出海经验，企业难以找到盈利突破点，找不到精准的客户来源'
                    },
                    {
                        lanhuimage0:
                            'https://lanhu.oss-cn-beijing.aliyuncs.com/psui7mmw2ao5h4sn987iqs9icd6dmq9z6o8323b980-d93b-4569-b298-f3af4866228b',
                        lanhutext0: '运营操盘难',
                        lanhutext1: '海外平台和特性与国内不同，缺少海外的运营经验'
                    },
                    {
                        lanhuimage0:
                            'https://lanhu.oss-cn-beijing.aliyuncs.com/psoqx4262z3scpaeelp0akhcmuev27hlrj5e209a82-bfd8-4315-b4df-b7ca69a4ad60',
                        lanhutext0: '效果沉淀难',
                        lanhutext1: '缺少海外运营数据、交易数据的效果检验、缺乏数据监控'
                    }
                ],
                constants: {}
            };
        },
        methods: {}
    };
</script>
<style scoped lang="css" src="./assets/index.css" />