import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_guanwang from '../views/lanhu_guanwang/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    component: lanhu_guanwang
  },
  {
    path: '/lanhu_guanwang',
    name: 'lanhu_guanwang',
    component: lanhu_guanwang
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
